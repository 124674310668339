import React from 'react';
import { AnimatedAscii } from '../image/asciis/AnimatedAscii';

export const MajinDonPatchInfo = () => {
  const domenecUrl = 'https://domenecmirallestagliabue.com';
  const polUrl = 'https://ponzi.cat'; // Local URL for Pol Crosas
  const gustavoUrl = 'https://speculative.monster'; // URL for Gustavo Cilleruelo
  const miquelUrl = 'https://media.licdn.com/dms/image/C5603AQFiznPrDYJM3Q/profile-displayphoto-shrink_200_200/0/1629914569213?e=2147483647&v=beta&t=PK00TbLzzv86ZUuGGKw3FTGjgG7b1E21tA56-VGzdS0'

  const redirectToUrl = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className='bg-white flex flex-col items-center justify-center h-screen text-black'>
      <div className='relative  p-12'>
        <div className='absolute top-5 left-10'>
          <button 
            onClick={() => redirectToUrl(domenecUrl)} // Redirect on button click
            className='hover:text-purple-400 px-2 py-1 text-left font-serif  text-purple-800'
          >
            ( ͡~ ͜ʖ ͡° ) dome ( ͡~ ͜ʖ ͡° )
          </button>
        </div>
        <div className='flex justify-center items-center h-full font-black'>
          <AnimatedAscii />
        </div>
        <div className='absolute bottom-6 right-10'>
          <button 
            onClick={() => redirectToUrl(gustavoUrl)} // Redirect on button click
            className='hover:text-purple-400 px-2 py-1 text-left font-serif  text-purple-800 transform rotate-180'
          >
           ᕙ( ͡° ͜ʖ ͡°)ᕗ gustavo ᕙ( ͡° ͜ʖ ͡°)ᕗ
          </button>
        </div>
        <div className='absolute top-11 right-0 transform -translate-y-1/3 translate-x-2'>
          <button 
            onClick={() => redirectToUrl(polUrl)} // Redirect on button click
            className='hover:text-purple-400 px-0 py-2 text-left font-serif  text-purple-800 transform rotate-90 origin-left translate-x-28'
          >
            (ﾉ･_-)☆ pol (ﾉ･_-)☆
          </button>
        </div>

        <div className='absolute top-96 left-0 transform -translate-y-40 -translate-x-36'>
          <button 
            onClick={() => redirectToUrl(miquelUrl)} // Redirect on button click
            className='hover:text-purple-400 px-2 py-0 text-left font-serif  text-purple-800 transform -rotate-90 origin-right'
          >
          ( ˘ ³˘)♥ miquel ( ˘ ³˘)♥
          </button>
        </div>

      </div>
    </div>
  );
};